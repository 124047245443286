import { Divider, Grid, Paper } from '@material-ui/core';
import FileSaver from 'file-saver';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import AppController from '../../../base/App.controller';
import DefaultContainer from '../../../layout/DefaultContainer';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import CustomThemeProvider from '../../common/theme/CustomThemeProvider';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import DisplayFieldWrapper from '../../common/wrappers/DisplayFieldWrapper';
import FormWrapper from '../../common/wrappers/FormWrapper';
import TextFieldWrapper from '../../common/wrappers/TextFieldWrapper';

function UploadCertificateVerifyType3({ AppStore, CertificateManagementStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, ['upload-certificate', 'common']);
    const ref = React.createRef();
    const payloadRef = React.createRef();

    const {
        uploadFile,
        rootStore,
        payload,
        signature,
        loading,
        handleChange,
    } = CertificateManagementStore.UploadCertificateStore;

    const _handleDownload = () => {
        const blob = new Blob([payload], {
            type: 'text/plain;charset=UTF-8',
        });
        FileSaver.saveAs(blob, 'payload.txt');
    };

    async function _onComplete() {
        try {
            await uploadFile(AppStore);
        } catch (e) {
            // Already handled by uploadFile
        }
    }

    return (
        <div className={'uploadCertVerify'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={rootStore.previousStep}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container spacing={4}>
                    <FormWrapper formRef={ref} onSubmit={_onComplete} isValid={!!signature}>
                        <Grid container item xs={12} spacing={4}>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={8}>
                                <div className={'title'}>{t('subVerify')}</div>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={'linkContainer'}>
                                    <CustomThemeProvider alt>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <div className={'sectionPayload'}>
                                                    <div className={'containerTitle'}>
                                                        {t('thisIsYourGenerated')}
                                                    </div>
                                                    <code>{t('generateInstructions')}</code>
                                                    <div>
                                                        <div className={'subtitle'}>
                                                            {t('payload')}
                                                        </div>
                                                        <DisplayFieldWrapper
                                                            rowsMax={12}
                                                            ref={payloadRef}
                                                            value={payload}
                                                            className={'payload'}
                                                            placeholder={'{}'}
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={7} lg={9} />
                                            <Grid item xs={5} lg={3}>
                                                <ButtonWrapper onClick={_handleDownload}>
                                                    {t('common:downloadPayload')}
                                                </ButtonWrapper>
                                            </Grid>
                                        </Grid>
                                    </CustomThemeProvider>
                                </Paper>
                            </Grid>

                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="signature"
                                    type="text"
                                    label={t('signature')}
                                    value={signature}
                                    onChange={handleChange}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} className={'bottomSection'}>
                                <Divider />
                                <Grid container spacing={4}>
                                    <Grid item xs={9} />
                                    <Grid item xs={3}>
                                        <ButtonWrapper
                                            onClick={_onComplete}
                                            disabled={!signature}
                                            loading={loading}>
                                            {t('common:complete')}
                                        </ButtonWrapper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormWrapper>
                </Grid>
            </DefaultContainer>
        </div>
    );
}

UploadCertificateVerifyType3.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject(
    'AppStore',
    'CertificateManagementStore',
)(observer(UploadCertificateVerifyType3));
