import React from 'react';
import { Next_Gen_Psd2, Polish_Api, Stet_Psd2 } from '../../../../../constants/bankStandards';
import NextGenPsd2Default from './next-gen-psd2/NextGenPsd2Default';
import PolishApiDefault from './polish-api/PolishApiDefault';
import StetPsd2Default from './stet-psd2/StetPsd2Default';
import UkOpenBankingDefault from './uk-open-banking/UkOpenBankingDefault';

export default class DefaultSettingsController {
    static getComponent(selectedStandard: string) {
        switch (selectedStandard) {
            case Next_Gen_Psd2:
                return <NextGenPsd2Default />;
            case Stet_Psd2:
                return <StetPsd2Default />;
            case Polish_Api:
                return <PolishApiDefault />;
            default:
                return <UkOpenBankingDefault />;
        }
    }
}
