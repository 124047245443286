/**
 * Provides an array for the key from the given object.
 *
 * @param obj
 * @param key
 * @returns provided key array
 */
export default function getKeyArray(obj: any, key: string) {
    const keys = obj
        .map((o: any) => {
            return o[key];
        })
        .filter((o: string) => !!o);
    return [...new Set(keys.sort())];
}
