import { Divider, Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import AppController from 'base/App.controller';
import DefaultContainer from 'layout/DefaultContainer';

import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';
import GenericSuccessModal from 'components/common/modals/success/GenericSuccess';
import Spinner from 'components/common/spinner/Spinner';
import CustomThemeProvider from 'components/common/theme/CustomThemeProvider';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import TableWrapper, { cellConstructor } from 'components/common/wrappers/TableWrapper';

// icons
import successIcon from 'images/password-reset.svg';

// helpers
import conditional from 'helpers/conditional/conditional';

// constants
import { TYPE_TWO } from 'constants/memberTypes';

function UploadCertificateComplete({ AppStore, CertificateManagementStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'upload-certificate');

    const { isRefund } = CertificateManagementStore;

    const {
        rootStore,
        reset,
        certType,
        fetchConfigBanks,
        configuredBanks,
        banksLoading,
        handleConfirm,
        confirmLoading,
        TableWrapperStore,
        GenericSuccessStore,
    } = CertificateManagementStore.UploadCertificateStore;
    const shouldOpenConfirmDialog =
        AppStore.member.memberType === TYPE_TWO && certType.startsWith('OB');

    useEffect(() => {
        if (shouldOpenConfirmDialog) {
            fetchConfigBanks(AppStore);
        }
    }, []);

    const _onComplete = async () => {
        await rootStore.sendToLanding();
        reset();
    };

    const header = [t('country'), t('bankName'), t('bankRegistrationGroup'), t('standard')];

    const bankText = (row) => {
        const value = row.identifier ? `${row.name}-${row.identifier}` : row.name;
        return cellConstructor.text({ row, keyId: 'bankId', value });
    };

    const cellTemplates = [
        (row) => cellConstructor.text({ row, keyId: 'bankId', field: 'country' }),
        (row) => bankText(row),
        (row) => cellConstructor.text({ row, keyId: 'bankId', field: 'bankRegistrationGroup' }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'bankId',
                field: 'standard',
                value: t(`standards.${row['openBankingStandard']}`),
            }),
    ];

    const _renderConfirguredBanks = () => {
        return (
            <>
                <div className={'table'}>
                    <TableWrapper
                        header={header}
                        enablePagination
                        disableRowSelect
                        cellTemplates={cellTemplates}
                        noDataMessage={t('noData')}
                        TableWrapperStore={TableWrapperStore}
                    />
                </div>
                <Grid item xs={8} className={'bottomSection'}>
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <ButtonWrapper
                                color={'secondary'}
                                className={'cancelButton'}
                                onClick={() => _onComplete()}>
                                {t('common:back')}
                            </ButtonWrapper>
                        </Grid>
                        <Grid item xs={3}>
                            <ButtonWrapper
                                className={'confirmButton'}
                                loading={confirmLoading}
                                onClick={() => handleConfirm(AppStore)}>
                                {t('confirm')}
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    };

    const _renderDefault = () => {
        return (
            <Grid item xs={8} className={'bottomSection'}>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <ButtonWrapper className={'okayButton'} onClick={_onComplete}>
                            {t('common:okay')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <div className={'uploadCertComplete'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={_onComplete}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container spacing={4}>
                    <Grid container item xs={12} spacing={4}>
                        {!isRefund && (
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        )}
                        <Grid item xs={8}>
                            <h2 className={'title'}>{t('thankYou')}</h2>
                            <div className={'description'}>
                                {conditional(
                                    configuredBanks.length > 0,
                                    t('configAll'),
                                    t('itMayTakeAFew'),
                                )}
                            </div>
                        </Grid>
                        {banksLoading ? (
                            <Grid item xs={12} className={'banksLoading'}>
                                <Spinner
                                    type={Spinner.TYPE_LARGE}
                                    color={Spinner.COLOR_SECONDARY}
                                />
                            </Grid>
                        ) : (
                            conditional(
                                configuredBanks.length > 0,
                                _renderConfirguredBanks(),
                                _renderDefault(),
                            )
                        )}
                    </Grid>
                </Grid>
            </DefaultContainer>
            <CustomThemeProvider alt>
                <GenericSuccessModal
                    Store={GenericSuccessStore}
                    title={t('modalTitle')}
                    subtitle={t('modalDescription')}
                    buttonText={t('common:okay')}
                    icon={successIcon}
                    onClick={() => _onComplete()}
                />
            </CustomThemeProvider>
        </div>
    );
}

UploadCertificateComplete.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject(
    'AppStore',
    'CertificateManagementStore',
)(observer(UploadCertificateComplete));
