import {
    Checkbox,
    Divider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Paper,
    TableCell,
    Card,
} from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from 'base/App.controller';
import { DONE, ERROR } from 'constants/bankRegistrationStatus';
import DefaultContainer from 'layout/DefaultContainer';
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';
import CustomThemeProvider from 'components/common/theme/CustomThemeProvider';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';
import TableWrapper, { cellConstructor } from 'components/common/wrappers/TableWrapper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import makeStyles from '@material-ui/styles/makeStyles';
import Spinner from 'components/common/spinner/Spinner';
import snackbar from 'hooks/useSnackbar';
import mappedBankRegistrationGroups from 'constants/mappedBankRegistrationGroups';
import { useRouter } from 'next/router';

const { useSnackbar } = snackbar;

function BankRegistration({ AppStore, RegisterStore }) {
    const router = useRouter();
    const { addSnack } = useSnackbar();

    const { t } = AppController.getTranslation(AppStore.host, ['bank-registration', 'common']);
    const {
        handleCheck,
        handleSubmit,
        bankDetails,
        checkIds,
        checkAll,
        loading,
        inProgress,
        toggleAccordion,
        accordions,
        isInLimit,
        showError,
        error,
        fetchOnMount,
        sendToBankRegistration,
    } = RegisterStore;
    const classes = useStyles();

    useEffect(() => {
        fetchOnMount(AppStore, router);
    }, []);

    const rowCheckBox = (row) => {
        const key = Math.random();
        if (row.bankRegistrationGroup) {
            return <TableCell key={key} />;
        }
        const bankId = row.id;
        return (
            <TableCell key={key} align={'center'}>
                <CustomThemeProvider alt>
                    <Checkbox checked={checkIds[bankId]} onChange={handleCheck} name={bankId} />
                </CustomThemeProvider>
            </TableCell>
        );
    };

    const headerCheckBox = (index) => {
        return (
            <CustomThemeProvider alt>
                <Checkbox
                    checked={checkAll[accordions[index].bankRegistrationGroup]}
                    onChange={(e) => handleCheck(e, index)}
                    name={'all'}
                />
            </CustomThemeProvider>
        );
    };

    const header = [t(''), t('bankId'), t('bankName'), t('registrationStatus')];

    const cellTemplates = [
        (row) => rowCheckBox(row),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                field: 'id',
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'id',
                value: row.name,
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'bankId',
                field: 'registrationStatus',
                // If errorLog exists and not DONE, then show error state, otherwise, show DONE or NOT_DONE
                value:
                    row.errorLog && row['registrationStatus'] !== DONE
                        ? t(`registrationStatuses.${ERROR}`)
                        : t(`registrationStatuses.${row['registrationStatus']}`),
            }),
    ];

    function renderContent() {
        if (error) {
            return <Paper className={classes.loadOrNoData}>{error}</Paper>;
        }

        if (showError) {
            return (
                <Paper className={classes.loadOrNoData}>
                    Country and API standard query parameters need to be set in the url.
                </Paper>
            );
        }

        if (loading) {
            return (
                <Paper className={classes.loadOrNoData}>
                    <Spinner type={Spinner.TYPE_LARGE} color={Spinner.COLOR_SECONDARY} />
                </Paper>
            );
        }

        if (bankDetails?.length === 0 || accordions.length === 0) {
            return (
                <Paper className={classes.loadOrNoData}>
                    <div>{t('noBanks')}</div>
                </Paper>
            );
        }

        return (
            <>
                {Object.keys(accordions).map((index) =>
                    accordions[index].bankRegistrationGroup !== '' ? (
                        <Accordion
                            expanded={accordions[index].isOpen}
                            className={classes['MuiExpansionPanel-root']}
                            key={index}
                            elevation={0}>
                            <AccordionSummary
                                className={classes['MuiExpansionPanelSummary-root']}
                                expandIcon={<ExpandMoreIcon />}
                                onClick={() => toggleAccordion(index)}>
                                <h4 className={classes['MuiExpansionPanelSummary-content']}>
                                    {headerCheckBox(index)}
                                    {_get(
                                        mappedBankRegistrationGroups,
                                        accordions[index].bankRegistrationGroup,
                                        accordions[index].bankRegistrationGroup,
                                    )}
                                    {!!accordions[index].errorLog &&
                                        accordions.registrationStatus !== DONE &&
                                        ` (${t(`registrationStatuses.${ERROR}`)})`}
                                </h4>
                            </AccordionSummary>
                            <AccordionDetails key={index}>
                                <Grid container>
                                    {!!accordions[index].errorLog &&
                                        accordions.registrationStatus !== DONE && (
                                        <Grid item xs={12}>
                                            <Card className={classes.card}>
                                                <b>{t('Bank Registration Message')}: </b>
                                                {accordions[index].errorLog}
                                            </Card>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <TableWrapper
                                            key={index}
                                            header={header}
                                            disableRowSelect
                                            cellTemplates={cellTemplates}
                                            noDataMessage={t('noBanks')}
                                            TableWrapperStore={accordions[index].TableWrapperStore}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        <Grid item xs={12} key={index}>
                            <TableWrapper
                                key={index}
                                header={header}
                                disableRowSelect
                                cellTemplates={cellTemplates}
                                noDataMessage={t('noBanks')}
                                TableWrapperStore={accordions[index].TableWrapperStore}
                            />
                        </Grid>
                    ),
                )}
                <div className={'buttonContainer'}>
                    <Grid container justify="flex-end" spacing={5}>
                        <Grid item xs={3}>
                            <ButtonWrapper
                                color="secondary"
                                size={'large'}
                                className={'cancelButton'}
                                onClick={() => sendToBankRegistration(router)}>
                                {t('common:cancel')}
                            </ButtonWrapper>
                        </Grid>
                        <Grid item xs={3} className={'confirm'}>
                            <ButtonWrapper
                                size={'large'}
                                color="primary"
                                loading={inProgress}
                                disabled={isInLimit}
                                tooltipText={t('confirmRegisterTooltip')}
                                showTooltip
                                onClick={() => handleSubmit(AppStore, router, addSnack, t)}
                                className={'confirmButton'}>
                                {t('confirm')}
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                </div>
            </>
        );
    }

    return (
        <div className={'Register'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={() => sendToBankRegistration(router)}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Divider />
                {renderContent()}
            </DefaultContainer>
        </div>
    );
}

const useStyles = makeStyles(() => {
    return {
        card: {
            marginTop: 16,
            padding: 16,
        },
        'MuiExpansionPanel-root': {
            background: 'transparent',
            margin: '20px 0 20px 0',
            '&:before': {
                display: 'none',
            },
        },
        'MuiExpansionPanelSummary-root': {
            padding: '0 2.34% 0 25px',
            background: 'white',
        },
        'MuiExpansionPanelSummary-content': {
            width: '80%',
            margin: '5px 0',
            fontSize: '20px',
        },
        loadOrNoData: {
            color: 'var(--milled-wine)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'var(--fs-x-large)',
            minHeight: '344px',
            margin: '20px 0 20px 0',
        },
    };
});

BankRegistration.propTypes = {
    AppStore: PropTypes.object.isRequired,
    RegisterStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'RegisterStore')(observer(BankRegistration));
