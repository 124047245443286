import { TextField, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
//types
import { IDisplayFieldWrapperProps as Props } from '../types';

const styles = () => ({
    root: {
        margin: '25px 0 0 0',
        backgroundColor: 'var(--ghost-white)',
        borderRadius: '4px',
        fontSize: '14px',
        padding: '20px',
        '& > div': {
            backgroundColor: 'var(--ghost-white)',
            color: 'var(--spun-pearl)',
            height: '100%',
        },
    },
});

const DisplayFieldWrapper = forwardRef<HTMLInputElement, Props>(
    ({ className, classes, placeholder, value, rowsMax = 10 }, ref) => {
        return (
            <TextField
                inputRef={ref}
                InputProps={{
                    readOnly: true,
                }}
                multiline
                rowsMax={rowsMax}
                value={value}
                className={clsx(classes.root, {
                    [className as string]: !!className,
                })}
                placeholder={placeholder}
            />
        );
    },
);

DisplayFieldWrapper.displayName = 'DisplayFieldWrapper';

export default withStyles(styles, { name: 'DisplayFieldWrapper' })(DisplayFieldWrapper);
