import { Divider, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
//types
import { ICustomDividerProps as Props } from '../types';

const styles = () => ({
    root: {
        marginBottom: '30px',
    },
});

const CustomDivider: React.FC<Props> = ({ classes }) => {
    return <Divider className={clsx('CustomDivider', classes.root)} />;
};

export default withStyles(styles, { name: 'CustomDivider' })(CustomDivider);
