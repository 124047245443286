import { Divider, Grid, MenuItem } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from '../../../base/App.controller';
import { countries } from '../../../constants/countries';
import DefaultContainer from '../../../layout/DefaultContainer';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import FormWrapper from '../../common/wrappers/FormWrapper';
import SelectWrapper from '../../common/wrappers/SelectWrapper';
import TextFieldWrapper from '../../common/wrappers/TextFieldWrapper';
import StepHandler from './StepHandler';

function CreateCertificateStep4({ AppStore, CertificateManagementStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'create-certificate');
    const ref = React.createRef();
    const {
        handleCompleteClick,
        rootStore,
        isDetailFormFilled,
        updateDetailField,
        companyDetailForm,
        loading,
    } = CertificateManagementStore.CreateCertificateStore;

    const [ctx, setCtx] = React.useState({
        isFormValid: false,
    });

    useEffect(() => {
        const isFormValid = isDetailFormFilled && ref.current.checkValidity();

        setCtx({ isFormValid });
    }, Object.values(companyDetailForm));

    const _handleChange = (event) => {
        updateDetailField(event.target.name, event.target.value);
    };

    const _handleSubmit = () => {
        return handleCompleteClick(AppStore);
    };

    return (
        <div className={'createCert4'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={rootStore.previousStep}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container spacing={4}>
                    <Grid container item xs={8} spacing={4}>
                        <Grid item xs={12}>
                            <Divider />
                            <div className={'title'}>{t('enterCompanyDetails')}</div>
                            <FormWrapper
                                className="form"
                                formRef={ref}
                                onSubmit={_handleSubmit}
                                isValid={ctx.isFormValid}>
                                <Grid container spacing={4} className="companyDetailForm">
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="organizationUnit"
                                            type="text"
                                            label={t('organizationUnit')}
                                            tooltipText={t('tooltips.organizationUnit')}
                                            value={companyDetailForm.organizationUnit}
                                            onChange={_handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SelectWrapper
                                            name="ncaCountry"
                                            label={t('ncaCountry')}
                                            value={companyDetailForm.ncaCountry}
                                            onChange={_handleChange}
                                            required>
                                            {countries.map((c) => (
                                                <MenuItem key={c.code} value={c.code}>
                                                    {c.name}
                                                </MenuItem>
                                            ))}
                                        </SelectWrapper>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="ncaLicenseNumber"
                                            type="text"
                                            label={t('ncaLicenseNumber')}
                                            tooltipText={t('tooltips.ncaLicenseNumber')}
                                            value={companyDetailForm.ncaLicenseNumber}
                                            onChange={_handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="companyNumber"
                                            type="text"
                                            label={t('companyNumber')}
                                            tooltipText={t('tooltips.companyNumber')}
                                            value={companyDetailForm.companyNumber}
                                            onChange={_handleChange}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="subjectAlternativeNames"
                                            type="text"
                                            label={t('subjectAlternativeNames')}
                                            value={companyDetailForm.subjectAlternativeNames}
                                            onChange={_handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </FormWrapper>
                        </Grid>
                        <Grid item xs={12} className={'bottomSection'}>
                            <Divider />
                            <Grid container spacing={4}>
                                <Grid item xs={6} />
                                <Grid item xs={3}>
                                    <ButtonWrapper
                                        onClick={rootStore.previousStep}
                                        color={'secondary'}>
                                        {t('common:back')}
                                    </ButtonWrapper>
                                </Grid>
                                <Grid item xs={3}>
                                    <ButtonWrapper
                                        className="completeButton"
                                        loading={loading}
                                        disabled={!ctx.isFormValid}
                                        onClick={_handleSubmit}>
                                        {t('common:complete')}
                                    </ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <StepHandler />
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
}

CreateCertificateStep4.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'CertificateManagementStore')(observer(CreateCertificateStep4));
