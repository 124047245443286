import { Grid, MenuItem } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AppController from 'base/App.controller';
import FormWrapper from 'components/common/wrappers/FormWrapper';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import { QSEAL, QWAC } from 'constants/cert';
// types
import { IRegistrationDetailsModalProps } from 'components/common/modals/types';
import { Itranslation } from 'types';

const PolishApiDefault: React.FC<IRegistrationDetailsModalProps> = ({
    AppStore,
    BankRegistrationStore,
}) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.host, [
        'bank-registration',
        'common',
    ]);
    const {
        form,
        isFormChanged,
        handleSave,
        handleChange,
        signingKeyCertificates,
        transportKeyCertificates,
        handleCertChange,
    } = BankRegistrationStore!.defaultSettingsModalStore;
    const ref = React.createRef();

    const handleSubmit = () => {
        return handleSave(AppStore);
    };

    return (
        <FormWrapper
            className="form sectionParameters"
            formRef={ref}
            onSubmit={handleSubmit}
            isValid={isFormChanged}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SelectWrapper
                        name="transportKeyCert"
                        label={t('selectTransportKeyCert')}
                        value={form.polishApiStandard.transportKeyCertName}
                        onChange={handleCertChange}
                        hideOptional>
                        {transportKeyCertificates
                            .filter((s) => s.type === QWAC || s.type === 'none')
                            .map((s) => (
                                <MenuItem key={s.name} value={s.name}>
                                    {s.name}
                                </MenuItem>
                            ))}
                    </SelectWrapper>
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="transportKeyId"
                        type="text"
                        label={t('transportKeyId')}
                        value={form.polishApiStandard.transportKeyId}
                        onChange={handleChange}
                        disabled
                        hideOptional
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectWrapper
                        name="signingKeyCert"
                        label={t('selectSigningKeyCert')}
                        value={form.polishApiStandard.signingKeyCertName}
                        onChange={handleCertChange}
                        hideOptional>
                        {signingKeyCertificates
                            .filter((s) => s.type === QSEAL || s.type === 'none')
                            .map((s) => (
                                <MenuItem key={s.name} value={s.name}>
                                    {s.name}
                                </MenuItem>
                            ))}
                    </SelectWrapper>
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="signingKeyId"
                        type="text"
                        label={t('signingKeyId')}
                        value={form.polishApiStandard.signingKeyId}
                        onChange={handleChange}
                        disabled
                        hideOptional
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="callbackUrls"
                        type="text"
                        label={t('callbackUrls')}
                        value={form.polishApiStandard.callbackUrls}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="contactEmail"
                        type="text"
                        label={t('contactEmail')}
                        value={form.polishApiStandard.contactEmail}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="address"
                        type="text"
                        label={t('address')}
                        value={form.polishApiStandard.address}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="phone"
                        type="text"
                        label={t('phone')}
                        value={form.polishApiStandard.phone}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="appName"
                        type="text"
                        label={t('appName')}
                        value={form.polishApiStandard.appName}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="tppId"
                        type="text"
                        label={t('tppId')}
                        value={form.polishApiStandard.tppId}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="certificateUri"
                        type="text"
                        label={t('certificateUri')}
                        value={form.polishApiStandard.certificateUri}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="clientUri"
                        type="text"
                        label={t('clientUri')}
                        value={form.polishApiStandard.clientUri}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
            </Grid>
        </FormWrapper>
    );
};

export default inject('AppStore', 'BankRegistrationStore')(observer(PolishApiDefault));
