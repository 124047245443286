import { Divider, Grid, Input, withStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import AppController from '../../../base/App.controller';
import DefaultContainer from '../../../layout/DefaultContainer';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import FormWrapper from '../../common/wrappers/FormWrapper';
import StepHandler from './StepHandler';

const StyledTextField = withStyles({
    root: {
        minHeight: '100px',
    },
})(Input);

function CreateCertificateStep2({ AppStore, CertificateManagementStore }) {
    const CHARACTER_LIMIT = 200;
    const { t } = AppController.getTranslation(AppStore.scope, 'create-certificate');
    const ref = React.createRef();
    const {
        dn,
        loading,
        updateDn,
        handleCompleteClickDn,
        rootStore,
    } = CertificateManagementStore.CreateCertificateStore;

    return (
        <div className={'createCert2'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={rootStore.previousStep}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container spacing={4}>
                    <Grid container item xs={8} spacing={4}>
                        <Grid item xs={12}>
                            <Divider />
                            <div className={'subtitle'}>{t('step2Subtitle')}</div>
                            <div className={'description'}>{t('step2Description')}</div>
                            <FormWrapper
                                className="form"
                                formRef={ref}
                                onSubmit={rootStore.nextStep}
                                isValid={!!dn}>
                                <Grid container spacing={4} className="dn">
                                    <Grid item xs={12}>
                                        <Tooltip title={t('tooltips.dn')} arrow>
                                            <StyledTextField
                                                name="dn"
                                                inputProps={{ maxLength: CHARACTER_LIMIT }}
                                                value={dn}
                                                label={t('dn')}
                                                onChange={updateDn}
                                                rows={'3'}
                                                required
                                                multiline
                                            />
                                        </Tooltip>
                                        <FormHelperText>{`${dn.length}/${CHARACTER_LIMIT}`}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </FormWrapper>
                            <Grid container item xs={12} spacing={3} className={'bottomSection'}>
                                <Grid item xs={6} md={4}>
                                    <ButtonWrapper
                                        disabled={!dn}
                                        loading={loading}
                                        onClick={() => handleCompleteClickDn(AppStore)}
                                        className="yesButton"
                                        color="primary">
                                        {t('common:yes')}
                                    </ButtonWrapper>
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <ButtonWrapper
                                        onClick={rootStore.nextStep}
                                        className="noButton"
                                        color="secondary">
                                        {t('common:no')}
                                    </ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <StepHandler />
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
}

CreateCertificateStep2.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'CertificateManagementStore')(observer(CreateCertificateStep2));
