import { Divider, Grid, MenuItem } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from '../../../base/App.controller';
import { countries } from '../../../constants/countries';
import DefaultContainer from '../../../layout/DefaultContainer';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import FormWrapper from '../../common/wrappers/FormWrapper';
import SelectWrapper from '../../common/wrappers/SelectWrapper';
import TextFieldWrapper from '../../common/wrappers/TextFieldWrapper';
import StepHandler from './StepHandler';

function CreateCertificateStep3({ AppStore, CertificateManagementStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'create-certificate');
    const ref = React.createRef();
    const {
        updateAddressField,
        isAddressFormFilled,
        isCountryFilled,
        companyAddressForm,
        states,
        zipLabel,
        stateLabel,
        rootStore,
    } = CertificateManagementStore.CreateCertificateStore;

    const [ctx, setCtx] = React.useState({
        isFormValid: false,
    });

    useEffect(() => {
        const isFormValid = isAddressFormFilled && ref.current.checkValidity();

        setCtx({ isFormValid });
    }, Object.values(companyAddressForm));

    const _handleChange = (event) => {
        updateAddressField(event.target.name, event.target.value);
    };

    return (
        <div className={'createCert3'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={rootStore.previousStep}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container spacing={4}>
                    <Grid container item xs={8} spacing={4}>
                        <Grid item xs={12}>
                            <Divider />
                            <div className={'title'}>{t('enterCompanyAddress')}</div>
                            <FormWrapper
                                className="form"
                                formRef={ref}
                                onSubmit={rootStore.nextStep}
                                isValid={ctx.isFormValid}>
                                <Grid container spacing={4} className="companyAddressForm">
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="companyName"
                                            type="text"
                                            label={t('companyName')}
                                            tooltipText={t('tooltips.companyName')}
                                            value={companyAddressForm.companyName}
                                            onChange={_handleChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="companyDomain"
                                            type="text"
                                            label={t('companyDomain')}
                                            tooltipText={t('tooltips.companyDomain')}
                                            value={companyAddressForm.companyDomain}
                                            onChange={_handleChange}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SelectWrapper
                                            name="country"
                                            label={t('country')}
                                            value={companyAddressForm.country}
                                            onChange={_handleChange}
                                            required>
                                            {countries.map((c) => (
                                                <MenuItem key={c.code} value={c.code}>
                                                    {c.name}
                                                </MenuItem>
                                            ))}
                                        </SelectWrapper>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="address"
                                            type="text"
                                            label={t('address')}
                                            value={companyAddressForm.address}
                                            onChange={_handleChange}
                                            disabled={!isCountryFilled}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="address2"
                                            type="text"
                                            label={t('address2')}
                                            value={companyAddressForm.address2}
                                            onChange={_handleChange}
                                            disabled={!isCountryFilled}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFieldWrapper
                                            name="city"
                                            type="text"
                                            label={t('city')}
                                            tooltipText={t('tooltips.city')}
                                            value={companyAddressForm.city}
                                            onChange={_handleChange}
                                            disabled={!isCountryFilled}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SelectWrapper
                                            name="state"
                                            label={t(stateLabel)}
                                            value={companyAddressForm.state}
                                            onChange={_handleChange}
                                            disabled={!isCountryFilled}
                                            required>
                                            {states.map((s) => (
                                                <MenuItem key={s} value={s}>
                                                    {s}
                                                </MenuItem>
                                            ))}
                                        </SelectWrapper>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextFieldWrapper
                                            name="zip"
                                            type="text"
                                            label={t(zipLabel)}
                                            value={companyAddressForm.zip}
                                            onChange={_handleChange}
                                            disabled={!isCountryFilled}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </FormWrapper>
                        </Grid>
                        <Grid item xs={12} className={'bottomSection'}>
                            <Divider />
                            <Grid container spacing={4}>
                                <Grid item xs={6} />
                                <Grid item xs={3}>
                                    <ButtonWrapper
                                        onClick={rootStore.previousStep}
                                        color={'secondary'}>
                                        {t('common:back')}
                                    </ButtonWrapper>
                                </Grid>
                                <Grid item xs={3}>
                                    <ButtonWrapper
                                        className="saveButton"
                                        disabled={!ctx.isFormValid}
                                        onClick={rootStore.nextStep}>
                                        {t('common:save')}
                                    </ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <StepHandler />
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
}

CreateCertificateStep3.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'CertificateManagementStore')(observer(CreateCertificateStep3));
