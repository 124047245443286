import React from 'react';
import { ADMIN, TOKEN } from '../../constants/banks';
import { RESELLER, UNDER_RESELLER } from '../../constants/clientType';
import CertificateManagementLanding from './CertificateManagementLanding';
import CertificateManagementLandingType3 from './CertificateManagementLanding.type3';
import CreateCertificateStep1 from './create-certificate/CreateCertificateStep1';
import CreateCertificateStep2 from './create-certificate/CreateCertificateStep2';
import CreateCertificateStep3 from './create-certificate/CreateCertificateStep3';
import CreateCertificateStep4 from './create-certificate/CreateCertificateStep4';
import CreateCertificateStep5 from './create-certificate/CreateCertificateStep5';
import UploadCertificate from './upload-certificate/UploadCertificate';
import UploadCertificateComplete from './upload-certificate/UploadCertificateComplete';
import UploadCertificateVerifyType3 from './upload-certificate/UploadCertificateVerify.type3';
import BankRegistration from '../bank-registration/BankRegistration';
import BankConfigurationMain from '../bank-configuration/BankConfigurationMain';
import Register from 'components/bank-registration/register/Register';

const COMPONENTS = {
    UPLOAD_1: <UploadCertificate />,
    UPLOAD_2: <UploadCertificateVerifyType3 />,
    UPLOAD_3: <UploadCertificateComplete />,
    CREATE_1: <CreateCertificateStep1 />,
    CREATE_2: <CreateCertificateStep2 />,
    CREATE_3: <CreateCertificateStep3 />,
    CREATE_4: <CreateCertificateStep4 />,
    CREATE_5: <CreateCertificateStep5 />,
    BANK_REGISTRATION_1: <BankRegistration />,
    BANK_REGISTRATION_2: <Register />,
    BANK_CONFIGURATION_1: <BankConfigurationMain />,
};

export default class CertificateManagementController {
    static getComponent(page, scope, clientType) {
        if (page.type) {
            return COMPONENTS[`${page.type}_${page.step}`];
        }

        switch (scope) {
            case TOKEN:
                return <CertificateManagementLanding />;
            case ADMIN:
                return <CertificateManagementLanding />;
            default:
                if (clientType === RESELLER || clientType === UNDER_RESELLER)
                    return <CertificateManagementLanding />;
                else return <CertificateManagementLandingType3 />;
        }
    }

    static setPath(CertificateManagementStore, subpath, scope) {
        switch (subpath) {
            case 'CREATE':
                switch (scope) {
                    case TOKEN:
                        CertificateManagementStore.sendToCreateCSR();
                        break;
                    default:
                        CertificateManagementStore.sendToLanding();
                        break;
                }
                break;
            case 'UPLOAD':
                CertificateManagementStore.sendToUploadCert();
                break;
            default:
                CertificateManagementStore.sendToLanding();
                break;
        }
    }
}
