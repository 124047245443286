import { inject, observer } from 'mobx-react';
import { Divider, Grid, MenuItem, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ALL } from 'constants/bankStandards';

import AppController from '../../base/App.controller';
import { DONE, NOT_DONE, ERROR } from '../../constants/bankRegistrationStatus';
import { ALL as All, API, EMAIL, DEV_PORTAL, MIXED } from '../../constants/bankRegistrationTypes';
import { supportedConfigCountries } from '../../constants/countries';
import DefaultContainer from '../../layout/DefaultContainer';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';
import DefaultSettingsModal from '../common/modals/bank-registration/default-settings/DefaultSettings';
import RegistrationDetailsModal from '../common/modals/bank-registration/RegistrationDetailsModal';
import CustomThemeProvider from '../common/theme/CustomThemeProvider';
import ButtonWrapper from '../common/wrappers/ButtonWrapper';
import TableWrapper, { cellConstructor } from '../common/wrappers/TableWrapper';
import SelectWrapper from '../common/wrappers/SelectWrapper';
import TextFieldWrapper from '../common/wrappers/TextFieldWrapper';
import { useRouter } from 'next/router';

function BankRegistration({ AppStore, BankRegistrationStore }) {
    const { t } = AppController.getTranslation(AppStore.host, ['bank-registration', 'common']);
    const router = useRouter();

    const {
        loading,
        fetchOnMount,
        provideDefaultSettings,
        handleChange,
        handleAutocomplete,
        getFilteredBanks,
        enableFilter,
        banks,
        isBankSelected,
        filterOptions,
        standardOptions,
        getBankStatusList,
        handleSelectRow,
        handleClear,
        selectedBank,
        enableRegister,
        TableWrapperStore,
        handleFilter,
        sendToRegister,
        rootStore,
        isTpp,
    } = BankRegistrationStore;

    useEffect(() => {
        fetchOnMount(AppStore, router);
    }, []);

    const formatCountries = (country) => {
        return country ? country.replace(/,/g, ' | ') : '-';
    };

    const header = [
        t('country'),
        t('bankName'),
        t('bankRegistrationGroup'),
        t('registrationType'),
        t('registrationStatus'),
        t('registrationErrorLog'),
    ];

    const cellTemplates = [
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'bankId',
                field: 'country',
                value: formatCountries(row['country']),
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'bankId',
                value: row.name,
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'bankId',
                field: 'bankRegistrationGroup',
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'bankId',
                field: 'registrationType',
                value: row['registrationType'] || '-',
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'bankId',
                field: 'registrationStatus',
                // If errorLog exists and not DONE, then show error state, otherwise, show DONE or NOT_DONE
                value:
                    row.errorLog && row['registrationStatus'] !== DONE
                        ? t(`registrationStatuses.${ERROR}`)
                        : t(`registrationStatuses.${row['registrationStatus']}`),
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'bankId',
                field: 'Error Log',
                value: row.errorLog || '-',
            }),
    ];

    const subHeader = [t('country'), t('bankName'), t('registrationType')];

    const subCellTemplates = [
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'bankRegistrationGroup',
                field: 'country',
                value: formatCountries(row['country']),
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'bankRegistrationGroup',
                value: row.name,
            }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'bankRegistrationGroup',
                field: 'registrationType',
                value: row['registrationType'] || '-',
            }),
    ];

    const _previousStep = () => {
        return router.push('/certificate-management');
    };

    return (
        <div className={'bankRegistration'}>
            <DefaultContainer>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Breadcrumbs
                            previousStep={isTpp ? rootStore.previousStep : _previousStep}
                            parentPath={t('parentTitle')}
                            currentPath={t('title')}
                        />
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={3}>
                        <ButtonWrapper
                            className={'defaultSettingsButton'}
                            color={'secondary'}
                            onClick={provideDefaultSettings}>
                            {t('defaultSettings')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
                <Divider />
                <div className={'descriptions'}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={9}>
                            <div>{t('defaultSettingsInstructions')}</div>
                        </Grid>
                        <Grid item xs={3}>
                            <ButtonWrapper
                                className={'registerButton'}
                                disabled={!enableRegister}
                                onClick={() => sendToRegister(router)}
                                tooltipText={t('registerToolTip')}
                                showTooltip>
                                {t('register')}
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <div className={'filterOptions'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} lg={2}>
                            <SelectWrapper
                                name="country"
                                label={t('country')}
                                value={filterOptions.country}
                                onChange={handleChange}
                                disabled={isBankSelected}
                                required>
                                {supportedConfigCountries.map((c) => (
                                    <MenuItem key={c.code} value={c.code}>
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                            <Autocomplete
                                id="bankId"
                                name="bankId"
                                options={banks}
                                value={selectedBank}
                                getOptionLabel={(option) =>
                                    option.identifier
                                        ? `${option.name}-${option.identifier}`
                                        : option.name
                                }
                                onChange={handleAutocomplete}
                                onInputChange={getFilteredBanks}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name={'bankName'}
                                        label={t('bankName')}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                            <TextFieldWrapper
                                name="bankRegistrationGroup"
                                label={t('bankRegistrationGroup')}
                                value={filterOptions.bankRegistrationGroup}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                            <SelectWrapper
                                name="standard"
                                label={t('standard')}
                                value={filterOptions.standard}
                                onChange={handleChange}
                                required>
                                {standardOptions.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {t(`standards.${s}`)}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                            <SelectWrapper
                                name="registrationStatus"
                                label={t('registrationStatus')}
                                value={filterOptions.registrationStatus}
                                onChange={handleChange}
                                required>
                                {[ALL, DONE, NOT_DONE, ERROR].map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {t(`registrationStatuses.${s}`)}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={2}>
                            <SelectWrapper
                                name="registrationType"
                                label={t('registrationType')}
                                value={filterOptions.registrationType}
                                onChange={handleChange}
                                required>
                                {[All, API, EMAIL, DEV_PORTAL, MIXED].map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {s}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                    </Grid>
                </div>
                <Grid container justify="flex-end" className={'filters'} spacing={3}>
                    <Grid item xs={12} sm={2}>
                        <ButtonWrapper
                            className={'clearButton'}
                            onClick={() => handleClear(AppStore, router)}>
                            {t('clear')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <ButtonWrapper
                            className={'filterButton'}
                            onClick={() => handleFilter(AppStore, router)}
                            disabled={!enableFilter}>
                            {t('filter')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
                <TableWrapper
                    header={header}
                    disableRowSelect={false}
                    callback={(row) => handleSelectRow(AppStore, row)}
                    cellTemplates={cellTemplates}
                    noDataMessage={t('noBanks')}
                    loading={loading}
                    TableWrapperStore={TableWrapperStore}
                    pageCallback={(currentPage) => getBankStatusList(AppStore, currentPage)}
                    serverSidePagination
                    canExpand
                    subHeader={subHeader}
                    subCellTemplates={subCellTemplates}
                />
                <CustomThemeProvider alt>
                    <DefaultSettingsModal />
                    <RegistrationDetailsModal />
                </CustomThemeProvider>
            </DefaultContainer>
        </div>
    );
}

BankRegistration.propTypes = {
    AppStore: PropTypes.object.isRequired,
    BankRegistrationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'BankRegistrationStore')(observer(BankRegistration));
