import { Checkbox, Divider, Grid, Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import AppController from '../../../base/App.controller';
import DefaultContainer from '../../../layout/DefaultContainer';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import CustomThemeProvider from '../../common/theme/CustomThemeProvider';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import StepHandler from './StepHandler';

function CreateCertificateStep1({ AppStore, CertificateManagementStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, ['create-certificate', 'common']);
    const { certTypes, rootStore, updateField } = CertificateManagementStore.CreateCertificateStore;

    const _handleCheck = (event) => {
        updateField(event.target.name, event.target.checked);
    };

    const _hasSelectedOption = () => {
        return Object.values(certTypes).reduce((prev, curr) => prev || curr, false);
    };

    return (
        <div className={'createCert1'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={rootStore.previousStep}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container spacing={4}>
                    <Grid container item xs={8} spacing={4}>
                        <Grid item xs={12}>
                            <Divider />
                            <div className={'subtitle'}>{t('selectCsrType')}</div>
                            <div className={'description'}>{t('csrTypeOrBoth')}</div>
                            <Grid container spacing={4}>
                                {['qwac', 'qsealc'].map((key) => (
                                    <Grid item xs={6} key={key}>
                                        <Paper className={'checkboxPaper'}>
                                            <CustomThemeProvider alt>
                                                <div className={'checkboxContainer'}>
                                                    <Checkbox
                                                        checked={certTypes[key]}
                                                        onChange={_handleCheck}
                                                        name={key}
                                                    />
                                                </div>
                                                <div className={'textContainer'}>
                                                    <div className={'title'}>{t(key)}</div>
                                                    <div className={'description'}>
                                                        {t(`${key}Detail`)}
                                                    </div>
                                                </div>
                                            </CustomThemeProvider>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={'bottomSection'}>
                            <Divider />
                            <Grid container spacing={4}>
                                <Grid item xs={6} />
                                <Grid item xs={3}>
                                    <ButtonWrapper
                                        onClick={rootStore.previousStep}
                                        color={'secondary'}>
                                        {t('common:back')}
                                    </ButtonWrapper>
                                </Grid>
                                <Grid item xs={3}>
                                    <ButtonWrapper
                                        onClick={rootStore.nextStep}
                                        disabled={!_hasSelectedOption()}>
                                        {t('common:save')}
                                    </ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <StepHandler />
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
}

CreateCertificateStep1.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'CertificateManagementStore')(observer(CreateCertificateStep1));
