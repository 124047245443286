import { Divider, Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import AppController from '../../../base/App.controller';
import downloadIcon from '../../../images/download.svg';
import DefaultContainer from '../../../layout/DefaultContainer';
import Breadcrumbs from '../../common/breadcrumbs/Breadcrumbs';
import ButtonWrapper from '../../common/wrappers/ButtonWrapper';
import StepHandler from './StepHandler';

function CreateCertificateStep5({ AppStore, CertificateManagementStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'create-certificate');
    const {
        handleDownload,
        userHasDownloaded,
        reset,
        rootStore,
    } = CertificateManagementStore.CreateCertificateStore;

    async function _handleComplete() {
        await rootStore.sendToLanding();
        reset();
    }

    return (
        <div className={'createCert5'}>
            <DefaultContainer>
                <Breadcrumbs
                    previousStep={() => rootStore.sendToLanding()}
                    parentPath={t('parentTitle')}
                    currentPath={t('title')}
                />
                <Grid container spacing={4}>
                    <Grid container item xs={8} spacing={4}>
                        <Grid item xs={12}>
                            <Divider />
                            <div className={'title'}>{t('downloadYourCert')}</div>
                            <div className={'description'}>{t('downloadInstructions')}</div>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <ButtonWrapper
                                        className="downloadButton"
                                        startIcon={<img src={downloadIcon} />}
                                        onClick={handleDownload}>
                                        {t('download')}
                                    </ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={'bottomSection'}>
                            <Divider />
                            <Grid container spacing={4}>
                                <Grid item xs={9} />
                                <Grid item xs={3}>
                                    <ButtonWrapper
                                        disabled={!userHasDownloaded}
                                        className="completeButton"
                                        onClick={_handleComplete}>
                                        {t('common:complete')}
                                    </ButtonWrapper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <StepHandler />
                    </Grid>
                </Grid>
            </DefaultContainer>
        </div>
    );
}

CreateCertificateStep5.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'CertificateManagementStore')(observer(CreateCertificateStep5));
