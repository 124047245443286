import { Grid } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from '../../base/App.controller';
import uploadIcon from '../../images/upload.svg';
import DefaultContainer from '../../layout/DefaultContainer';
import GenericWarningModal from '../common/modals/warning/GenericWarning';
import ButtonWrapper from '../common/wrappers/ButtonWrapper';
import CustomDivider from '../common/wrappers/CustomDivider';
import TableWrapper, { cellConstructor } from '../common/wrappers/TableWrapper';
const { publicRuntimeConfig } = require('next/config').default();

function CertificateManagementLanding({ AppStore, CertificateManagementStore }) {
    const linkList = JSON.parse(JSON.stringify(publicRuntimeConfig.links.child));
    const { t } = AppController.getTranslation(AppStore.scope, 'certificate-management');
    const {
        sendToUploadCert,
        sendToCreateCSR,
        sendToBankRegistration,
        sendToBankConfiguration,
        selectedRow,
        updateSelectRow,
        TableWrapperStore,
        loading,
        CertificateWarningModal,
        isRefund,
        isTpp,
        isAdmin,
        hasCert,
    } = CertificateManagementStore;

    useEffect(() => {
        CertificateManagementStore.fetchKeys(AppStore);
    }, []);

    const header = [t('id'), t('serialNumber'), t('name'), t('format'), t('status'), t('expiry')];

    const cellTemplates = [
        (row) => cellConstructor.text({ row, keyId: 'id', field: 'id' }),
        (row) =>
            cellConstructor.text({
                row: row.certificate,
                keyId: 'serialNumber',
                field: 'serialNumber',
            }),
        (row) =>
            cellConstructor.text({ row: row.certificate, keyId: 'serialNumber', field: 'name' }),
        (row) =>
            cellConstructor.text({ row: row.certificate, keyId: 'serialNumber', field: 'format' }),
        (row) =>
            cellConstructor.text({ row: row.certificate, keyId: 'serialNumber', field: 'status' }),
        (row) =>
            cellConstructor.text({ row: row.certificate, keyId: 'serialNumber', field: 'expiry' }),
    ];

    return (
        <div className={'certificateManagement'}>
            <DefaultContainer>
                {!(isRefund || isTpp) && !isAdmin && (
                    <>
                        <h1>{t('title')}</h1>
                        <CustomDivider />
                    </>
                )}
                <h2>{t('sub')}</h2>
                <Grid container justify="flex-end" spacing={2}>
                    {!isRefund && (
                        <Grid item xs={7} md={3} lg={2}>
                            <ButtonWrapper onClick={sendToCreateCSR}>
                                {t('createCSR')}
                            </ButtonWrapper>
                        </Grid>
                    )}
                    <Grid item xs={7} md={4} lg={3}>
                        <ButtonWrapper
                            onClick={sendToUploadCert}
                            startIcon={<img src={uploadIcon} />}>
                            {t('uploadCSR')}
                        </ButtonWrapper>
                    </Grid>
                    {!isRefund && (
                        <>
                            {!linkList.bankRegistration?.disabled && (
                                <Grid item xs={7} md={4} lg={3}>
                                    <ButtonWrapper
                                        className="bankRegistrationButton"
                                        disabled={!hasCert}
                                        onClick={sendToBankRegistration}>
                                        {t('bankRegistration')}
                                    </ButtonWrapper>
                                </Grid>
                            )}
                            {!linkList.bankConfiguration?.disabled && (
                                <Grid item xs={7} md={4} lg={3}>
                                    <ButtonWrapper
                                        disabled={!hasCert}
                                        onClick={sendToBankConfiguration}>
                                        {t('bankConfig')}
                                    </ButtonWrapper>
                                </Grid>
                            )}
                        </>
                    )}
                    <Grid item xs={7} md={4} lg={isRefund ? 3 : 1}>
                        <ButtonWrapper
                            className="deleteButton"
                            disabled={isEmpty(selectedRow)}
                            onClick={() => CertificateWarningModal.openModal()}>
                            {t('delete')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
                <TableWrapper
                    TableWrapperStore={TableWrapperStore}
                    header={header}
                    enablePagination
                    disableRowSelect={false}
                    callback={updateSelectRow}
                    loading={loading}
                    maxRowsPerPage={10}
                    cellTemplates={cellTemplates}
                    noDataMessage={t('youNeedCerts')}
                />
            </DefaultContainer>
            <GenericWarningModal
                Store={CertificateManagementStore.CertificateWarningModal}
                onClick={CertificateManagementStore.deleteKey}
                title={t('warningModalTitle')}
                subtitle={t('warningModalDescription')}
                buttonText={t('delete')}
                loading={loading}
            />
        </div>
    );
}

CertificateManagementLanding.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject(
    'AppStore',
    'CertificateManagementStore',
)(observer(CertificateManagementLanding));
