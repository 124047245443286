import { Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import React from 'react';
import DialogWrapper from '../../wrappers/DialogWrapper';
import LinkWrapper from '../../wrappers/LinkWrapper';
import { IGenericSuccessModalProps } from '../types';

const GenericSuccessModal: React.FC<IGenericSuccessModalProps> = ({
    className,
    Store,
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    icon,
    buttonText,
    onClick = async () => Promise.resolve(),
    title,
    subtitle,
    link,
}) => {
    const _onClose = async () => {
        Store.closeModal();
        await onClick();
    };

    return (
        <DialogWrapper
            open={Store.isOpen}
            onClose={_onClose}
            disableEscapeKeyDown={disableEscapeKeyDown}
            disableBackdropClick={disableBackdropClick}
            hideDivider
            className={'SuccessModal ' + className}>
            <img className="successIcon" src={icon} />
            <h2>{title}</h2>
            {subtitle && <div className={'description'}>{subtitle}</div>}
            {link && (
                <LinkWrapper href={link} className={'link'} target="_blank" rel="noopener">
                    {link}
                </LinkWrapper>
            )}
            <Button
                variant="contained"
                color="primary"
                className={'modalCloseButton'}
                onClick={_onClose}>
                {buttonText}
            </Button>
        </DialogWrapper>
    );
};

export default observer(GenericSuccessModal);
