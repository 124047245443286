import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AppController from '../../../../base/App.controller';
import ButtonWrapper from '../../wrappers/ButtonWrapper';
import DialogWrapper from '../../wrappers/DialogWrapper';
import NextGenPsd2Modal from '../bank-configuration/next-gen-psd2/NextGenPsd2';
import PolishApiModal from '../bank-configuration/polish-api/PolishApi';
import StetPsd2Modal from '../bank-configuration/stet-psd2/StetPsd2';
import UKOpenBankingModal from '../bank-configuration/uk-open-banking/UKOpenBanking';
import CustomThemeProvider from '../../theme/CustomThemeProvider';
// types
import { IRegistrationDetailsModalProps, IbankConfigs } from '../types';
import { Itranslation } from 'types';
import { typedObjectKeys } from 'helpers/typed-object-keys/typed-object.keys';

const { publicRuntimeConfig } = require('next/config').default();

const RegistrationDetailsModal: React.FC<IRegistrationDetailsModalProps> = ({
    AppStore,
    BankRegistrationStore,
}) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.host, [
        'bank-registration',
        'common',
    ]);
    const { registrationDetailsModalStore } = BankRegistrationStore!;
    const {
        isOpen,
        closeModal,
        bankConfigs,
        BankConfigurationStore,
        addConfig,
    } = registrationDetailsModalStore;

    const _openSupportTicket = () => {
        return window.open(publicRuntimeConfig.externalLinks.support.onboard, '_blank', 'noopener');
    };

    const formatDetails = (bankConfigs: IbankConfigs, field: keyof IbankConfigs) => {
        const arr = bankConfigs[field];
        if (field === 'type') {
            return arr || '-';
        } else {
            return arr;
        }
    };

    return (
        <div>
            <DialogWrapper
                open={isOpen}
                onClose={closeModal}
                className={'RegistrationDetailsModal'}
                title={t('title')}>
                <div className={'formContainer'}>
                    {typedObjectKeys(bankConfigs).map((b) => {
                        return (
                            formatDetails(bankConfigs, b) && (
                                <div key={b} className={'details'}>
                                    <span className={'title'}>{t(`${b}`)}</span>:
                                    <span className={'field'}>{formatDetails(bankConfigs, b)}</span>
                                </div>
                            )
                        );
                    })}
                </div>
                <div>
                    <Grid
                        container
                        justify="space-evenly"
                        spacing={1}
                        className={'buttonContainer'}>
                        <Grid item xs={3}>
                            <ButtonWrapper
                                color="secondary"
                                className={'cancelButton'}
                                onClick={closeModal}>
                                {t('common:cancel')}
                            </ButtonWrapper>
                        </Grid>
                        <Grid item xs={5}>
                            <ButtonWrapper
                                color="primary"
                                className={'requestSupportButton'}
                                onClick={_openSupportTicket}>
                                {t('requestSupport')}
                            </ButtonWrapper>
                        </Grid>
                        <Grid item xs={4}>
                            <ButtonWrapper
                                size={'large'}
                                color="primary"
                                className={'addButton'}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                    addConfig(e, AppStore)
                                }>
                                {t('addConfig')}
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                </div>
            </DialogWrapper>
            <CustomThemeProvider alt>
                <UKOpenBankingModal
                    AppStore={AppStore}
                    BankConfigurationStore={BankConfigurationStore}
                />
                <NextGenPsd2Modal
                    AppStore={AppStore}
                    BankConfigurationStore={BankConfigurationStore}
                />
                <StetPsd2Modal
                    AppStore={AppStore}
                    BankConfigurationStore={BankConfigurationStore}
                />
                <PolishApiModal
                    AppStore={AppStore}
                    BankConfigurationStore={BankConfigurationStore}
                />
            </CustomThemeProvider>
        </div>
    );
};

export default inject('AppStore', 'BankRegistrationStore')(observer(RegistrationDetailsModal));
