import { Divider, Paper } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import AppController from '../../../base/App.controller';
import checkIcon from '../../../images/icon-check.svg';
import IconWrapper from '../../common/wrappers/IconWrapper';

function StepHandler({ AppStore, CertificateManagementStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'create-certificate');
    const { page } = CertificateManagementStore;

    return (
        <Paper className={'stepManager'}>
            <div className={'title'}>{t('youNeedToBecome')}</div>
            <div className={'subtitle'}>{t('certificationRequired')}</div>
            <div className={'description'}>{t('certificationNote')}</div>

            {['csrType', 'enterDn', 'enterAddress', 'enterDetails', 'download'].map(
                (key, index) => (
                    <div key={key}>
                        <div className={'stepList'}>
                            <span className="text">{t(key)}</span>
                            <IconWrapper icon={checkIcon} disabled={index >= page.step} stroke />
                        </div>
                        <Divider />
                    </div>
                ),
            )}
        </Paper>
    );
}

StepHandler.propTypes = {
    AppStore: PropTypes.object.isRequired,
    CertificateManagementStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'CertificateManagementStore')(observer(StepHandler));
